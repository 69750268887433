import { useEffect, useMemo } from 'react'

import type { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import {
  getAccessToken,
  gsspMiddleware,
  getStaticPath,
  useAccessToken,
} from '@b-stock/bstock-next'
import { FormattedMessage } from '@b-stock/bstock-react'
import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'
import { DocumentDtoDocTypeEnum } from '@b-stock/listing-api-client'
import type { StoreFrontDto } from '@b-stock/listing-api-client'

import type { AuctionFiltersState } from '@components/AuctionSearchProvider'
import AuctionSearchProvider, {
  composeAuctionStateFromQueryParams,
  composeQueryFromAuctionState,
} from '@components/AuctionSearchProvider'
import type { SearchState } from '@components/BaseSearchProvider/types'
import { ContentContainer } from '@components/ContentContainer'
import { AuctionLayout } from '@components/features/auction/AuctionsLayout'
import {
  InfoSection,
  DocumentLink,
} from '@components/features/auction/details/Policies/Policies'
import {
  DetailsValue,
  TableContainer,
} from '@components/features/auction/details/shared'
import { UnloggedWarning } from '@components/features/auction/details/UnloggedWarning'
import StandardLayout from '@components/layouts/StandardLayout'
import { getSellerPageFilters } from '@components/SearchFilters'
import demandParam from '@helpers/demandParam'
import useAnalytics from '@hooks/useAnalytics'
import NotFound from '@pages/_error.page'
import listingSearchQuery from '@queries/listingSearchQuery/listingSearchQuery'
import storefrontQuery, { getStorefront } from '@queries/storefrontQuery'

type HeaderBackgroundProps = { $img: string }

const HeaderWrap = styled.div`
  overflow: hidden;
  margin-top: -1rem;
  height: 29.25rem;
  margin-bottom: 4rem;
  @media ${Breakpoints.max.medium} {
    height: 21rem;
    max-height: 21rem;
  }
  @media ${Breakpoints.max.small} {
    height: 12rem;
    max-height: 12rem;
  }
`

const HeaderBlurWrap = styled.div`
  width: 100%;
  overflow: hidden;
  z-index: -1;
  position: absolute;
  height: 26.75rem;
  @media ${Breakpoints.max.medium} {
    display: none;
  }
  @media ${Breakpoints.max.small} {
    display: none;
  }
`

const HeaderBannerWrap = styled(ContentContainer)`
  padding-top: 4.25rem;
  padding-bottom: 3.5rem;
  height: 29.25rem;
  @media ${Breakpoints.max.medium} {
    padding: 0;
    margin: 0;
    height: 21rem;
    max-height: 21rem;
  }
  @media ${Breakpoints.max.small} {
    padding: 0;
    margin: 0;
    height: 12rem;
    max-height: 12rem;
  }
`

const HeaderBannerInnerWrap = styled(ContentContainer)`
  height: 24rem;
  @media ${Breakpoints.max.medium} {
    padding: 0;
    margin: 0;
    height: 21rem;
    max-height: 21rem;
  }
  @media ${Breakpoints.max.small} {
    padding: 0;
    margin: 0;
    height: 12rem;
    max-height: 12rem;
  }
`

const HeaderBlur = styled.div<HeaderBackgroundProps>`
  height: 26.75rem;
  filter: blur(3.75rem);
  width: 100%;
  background-image: ${({ $img }) => `url(${$img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`

const HeaderBackground = styled.div<HeaderBackgroundProps>`
  height: 19rem;
  width: 100%;
  background-image: ${({ $img }) => `url(${$img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.5rem 0.5rem 0 0;
  @media ${Breakpoints.max.medium} {
    border-radius: 0;
    height: 15rem;
    max-height: 15rem;
  }
  @media ${Breakpoints.max.small} {
    border-radius: 0;
    height: 7.5rem;
    max-height: 7.5rem;
  }
`

const StyledContainer = styled(ContentContainer)`
  z-index: 1;
  position: relative;
`

const SellerWrap = styled.div`
  background-color: ${designColors.neutral.lightGray};
  height: 6rem;
  max-height: 6rem;
  border-radius: 0 0 0.5rem 0.5rem;
  @media ${Breakpoints.max.medium} {
    border-radius: 0;
    background-color: ${designColors.neutral.white};
  }
  @media ${Breakpoints.max.small} {
    border-radius: 0;
    background-color: ${designColors.neutral.white};
    height: 5rem;
    max-height: 5rem;
  }
`

const SellerContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  background-color: ${designColors.neutral.lightGray};
  height: 6rem;
  max-height: 6rem;
  border-radius: 0 0 0.5rem 0.5rem;
  @media ${Breakpoints.max.medium} {
    border-radius: 0;
    background-color: ${designColors.neutral.white};
  }
  @media ${Breakpoints.max.small} {
    border-radius: 0;
    background-color: ${designColors.neutral.white};
    height: 5rem;
    max-height: 5rem;
  }
`

const SellerImgWrap = styled.div`
  min-height: 6rem;
  min-width: 10rem;
  height: 6rem;
  width: 6rem;
  position: relative;
  @media ${Breakpoints.max.medium} {
    min-height: auto;
    min-width: auto;
    height: 5rem;
    width: 5rem;
    margin: 0 0 0 2rem;
  }
  @media ${Breakpoints.max.small} {
    min-height: auto;
    min-width: auto;
    height: 4rem;
    width: 4rem;
    margin: 0 0 0 1rem;
  }
`

const SellerImg = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 10rem;
  height: auto;
  max-height: 10rem;
  object-fit: cover;
  border-radius: 50%;
  margin: auto auto 0.5rem 1.5rem;
  box-shadow: 0 0.048rem 0.143rem 0 rgba(20, 26, 30, 0.1);
  @media ${Breakpoints.max.medium} {
    box-shadow: none;
    margin: 0;
  }
  @media ${Breakpoints.max.small} {
    box-shadow: none;
    margin: 0;
  }
`

const SellerInfo = styled.div`
  flex-grow: 1;
`

const SellerTitle = styled.h1`
  ${Typography.Display}
`

const PoliciesTitle = styled.h2`
  ${Typography.Title2}
  margin: 0;
`

const LayoutWrap = styled.div`
  padding-top: 1rem;
`

export const UnloggedWarningWrap = styled.div`
  margin-bottom: 5rem;
`

const SellerDetailsPage = ({
  initialAuctionSearchState,
  sellerId,
  referral_url,
}: {
  initialAuctionSearchState: SearchState<AuctionFiltersState>
  sellerId: string
  referral_url: string
}) => {
  const { query } = useRouter()
  const { formatMessage } = useIntl()
  const { pageViewed } = useAnalytics()
  const accessToken = useAccessToken()
  const isLoggedIn = !!accessToken

  // load url path param
  const slug = demandParam(query, 'sellerSlug')

  // Fetch one record via search all
  const { isLoading, data: storefront } = storefrontQuery.useQuery({
    slug: [slug],
  })

  const sellerName = storefront?.name || slug
  const logo = storefront?.documents?.find((doc) => doc.docType === 'LOGO')
  const logoUrl = logo?.url

  const hasLimitedVisibility =
    storefront?.options?.listingsLimitedVisibility && !isLoggedIn

  const forceFilters = useMemo(() => ({ sellerId: [sellerId] }), [sellerId])

  const bannerUrl = getBannerByStorefront(storefront, { width: 1224 })

  useEffect(() => {
    if (storefront) {
      pageViewed('seller_storefront', 'buyer_portal', 'buyer', {
        seller_account_id: sellerId,
        storefront_name: storefront.name,
        referral_url: referral_url,
      })
    }
  }, [pageViewed, referral_url, sellerId, storefront])

  // 🐞 🖼️ UNMASK below to force images for debug purposes
  // const logoUrl = 'https://imgproxy.pinside.com/s_mlpSA8yblVYN1wczM7tQDI82VuS5BWmH1VH0NuPwM/rs:fill:300:300/c:0:0:sm/ush:always:1.5:20/q:85/fn:Pinside_shop__/aHR0cHM6Ly9vLnBpbnNpZGUuY29tLzUvNTAvYmIvNTUwYmI5ZWJhNWViYjI2MzQ1YTgwYTZhYTAyM2MxYzUxYTJkYWJlOC5wbmc'
  // const bannerUrl = 'https://popicon.life/wp-content/uploads/2021/11/macys_parade_banner.png'

  if (!isLoading && !storefront?._id) return <NotFound />

  return (
    <StandardLayout noContainer>
      <HeaderWrap>
        <HeaderBlurWrap>
          <HeaderBlur $img={bannerUrl} />
        </HeaderBlurWrap>
        <HeaderBannerWrap>
          <HeaderBannerInnerWrap>
            <HeaderBackground $img={bannerUrl} />
            <SellerWrap>
              <SellerContainer>
                <SellerImgWrap>
                  <SellerImg
                    src={
                      logoUrl ||
                      getStaticPath('images/placeholder-auction-image.png')
                    }
                  />
                </SellerImgWrap>
                <SellerInfo>
                  <SellerTitle>{sellerName}</SellerTitle>
                </SellerInfo>
              </SellerContainer>
            </SellerWrap>
          </HeaderBannerInnerWrap>
        </HeaderBannerWrap>
      </HeaderWrap>
      <StyledContainer>
        <LayoutWrap>
          {storefront ? (
            hasLimitedVisibility ? (
              <UnloggedWarningWrap>
                <UnloggedWarning
                  reason={formatMessage(
                    { id: 'AuctionList.unloggedReason' },
                    { seller: storefront.name }
                  )}
                />
              </UnloggedWarningWrap>
            ) : (
              <>
                <AuctionSearchProvider
                  forceFilters={forceFilters}
                  initialState={initialAuctionSearchState}
                >
                  <AuctionLayout
                    headerMessage="AuctionList.auctions"
                    filters={getSellerPageFilters()}
                    showSoldBy={false}
                    showSearch
                    searchText={formatMessage(
                      { id: 'Seller.search.storefront' },
                      { sellerName }
                    )}
                  />
                </AuctionSearchProvider>

                <TableContainer>
                  <PoliciesTitle>
                    <FormattedMessage id="Auction.title.policies" />
                  </PoliciesTitle>
                  <div>
                    <InfoSection>
                      <DetailsValue>
                        <FormattedMessage
                          id="Auction.body.policies"
                          values={{
                            link: (content: React.ReactNode) => (
                              <DocumentLink
                                documents={storefront.documents}
                                documentType={
                                  DocumentDtoDocTypeEnum.TermsOfService
                                }
                              >
                                {content}
                              </DocumentLink>
                            ),
                          }}
                        />
                      </DetailsValue>
                    </InfoSection>
                  </div>
                </TableContainer>
              </>
            )
          ) : null}
        </LayoutWrap>
      </StyledContainer>
    </StandardLayout>
  )
}

// TODO: This query below is NOT begin used due to not being able to convert {sellerSlug} into storefront.members[0].accountId to force filter of only 1 storefront
// SSR communication details controller
export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const accessToken = await getAccessToken(ctx)
  const initialAuctionSearchState = composeAuctionStateFromQueryParams(
    ctx.query
  )

  // For analytics
  const referral_url = ctx.req.headers.referer ?? ''

  const slug = ctx.query.sellerSlug as string
  const storefront = await getStorefront(accessToken, { slug: [slug] })
  const sellerId = storefront?.members[0]?.accountId
  const hasLimitedVisibility = storefront?.options?.listingsLimitedVisibility
  const isLoggedIn = !!accessToken

  const prefetchQueries = [storefrontQuery.prefetch({ slug: [slug] })]
  if (hasLimitedVisibility && !isLoggedIn) {
    prefetchQueries.push(
      listingSearchQuery.prefetch({
        params: {
          ...composeQueryFromAuctionState(initialAuctionSearchState),
          sellerId: [sellerId ?? ''],
        },
      })
    )
  }

  return gsspMiddleware({
    ctx,
    prefetchQueries,
    props: {
      initialAuctionSearchState,
      sellerId,
      referral_url,
    },
  })
}

export default SellerDetailsPage

export function getBannerByStorefront(
  storefront: StoreFrontDto | null | undefined,
  { width }: { width: number }
) {
  const docs = storefront?.documents
  const banner = docs?.find(
    (doc) => doc.docType === DocumentDtoDocTypeEnum.Banner
  )

  // look for a variant OR just use the defined document
  const preferred =
    banner?.variants &&
    banner.variants
      .filter((v) => (v.dimensions?.width ?? 0) >= width)
      .sort((a, b) => (a.dimensions?.width ?? 0) - (b.dimensions?.width ?? 0))

  const bestBanners = preferred?.length ? preferred : [banner]
  const definedUrl = bestBanners[0]?.url

  return definedUrl || getStaticPath('images/placeholder-auction-image.png')
}
